<div class="container-fluid">
    <!-- Reset Password page start-->
    <div class="authentication-main">
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="auth-innerright">
            <div class="authentication-box">
              <div class="text-center"><img src="assets/images/logo360.png" alt="" /></div>
              <div class="card mt-4 p-4">
                <form class="theme-form" (ngSubmit)="onSubmit()">
                  <h5 class="f-16 mb-3 f-w-600">CREATE YOUR PASSWORD</h5>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input class="form-control" type="email" name="email" placeholder="{{email}}" disabled>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">New Password</label>
                    <input class="form-control" type="password" [(ngModel)]="newPassword" name="newPassword" placeholder="*****">
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Retype Password</label>
                    <input class="form-control" type="password" [(ngModel)]="confirmPassword" name="confirmPassword" placeholder="*****">
                  </div>
                  <div class="form-group row mb-0">
                    <div class="form-group btn-showcase d-flex">
                      <button class="btn btn-outline-success" type="submit">Done</button>
                      <button class="btn btn-outline-danger" type="submit" (click)="onCancel()">Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Reset Password page end-->
  </div>