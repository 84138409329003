
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { User } from '../../model/user-management/user';
import { FormGroup, FormControl, FormBuilder, Validators, AsyncValidator } from '@angular/forms';
import { LanguageService } from '../../services/language/language.service';

var body = document.getElementsByTagName("body")[0];

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    registerForm: FormGroup;
    public menuItems: Menu[];
    public items: Menu[];
    public searchResult: boolean = false;
    public searchResultEmpty: boolean = false;
    public openNav: boolean = false
    public right_sidebar: boolean = false
    public text: string;
    public elem: any;
    public editVisible: boolean = false;
    public isOpenMobile: boolean = false;
    // public selectedLang: string = 'es';
    public userModel: User;
    public selectedLang: string;
    @Output() rightSidebarEvent = new EventEmitter<boolean>();

    constructor(
        public navServices: NavService,
        @Inject(DOCUMENT) private document: any,
        private translate: LanguageService,
        private authService: AuthService,
        private router: Router,
        private fb: FormBuilder) {
        this.registerForm = this.fb.group({
            userName: new FormControl('', Validators.required),
        });
        const userData = JSON.parse(localStorage.getItem('userLogged') || '');
        this.userModel = {
            uid: userData.uid,
            email: userData.email,
            userName: userData.userName,
            password: userData.password,
            role: userData.role,
            trainerUid: userData.trainerUid,
            profilePath: userData.profilePath,
            measurementSystem: userData.measurementSystem,
            language: userData.language,
            birthDate: userData.birthDate,
            gender: userData.gender
        };

        this.selectedLang = this.userModel.language;
        this.translate.changeLanguage(this.selectedLang, this.userModel);
    }

    onEditUser() {
        this.editVisible = true;
    }

    updateUser() {
        this.onCancel();
    }

    onCancel() {
        this.editVisible = false;
        this.registerForm = this.fb.group({
            userName: new FormControl('', Validators.required)
        })
    }


    ngOnDestroy() {
        this.removeFix();
    }


    right_side_bar() {
        this.right_sidebar = !this.right_sidebar
        this.rightSidebarEvent.emit(this.right_sidebar)
    }

    collapseSidebar() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar
    }

    openMobileNav() {
        this.openNav = !this.openNav;
    }

    public changeLanguage(lang: any) {
        this.userModel.language = lang;
        this.translate.changeLanguage(lang, this.userModel);
        this.selectedLang = lang;
    }

    searchTerm(term: any) {
        term ? this.addFix() : this.removeFix();
        if (!term) return this.menuItems = [];
        let items: any = [];
        term = term.toLowerCase();
        this.items.filter((menuItems: any) => {
            if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
                items.push(menuItems);
            }
            if (!menuItems.children) return false
            menuItems.children.filter((subItems: any) => {
                if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
                    subItems.icon = menuItems.icon
                    items.push(subItems);
                }
                if (!subItems.children) return false
                subItems.children.filter((suSubItems: any) => {
                    if (suSubItems.title.toLowerCase().includes(term)) {
                        suSubItems.icon = menuItems.icon
                        items.push(suSubItems);
                    }
                })

                return
            })
            this.checkSearchResultEmpty(items)
            this.menuItems = items
            return
        });
        return
    }

    checkSearchResultEmpty(items: any) {
        if (!items.length)
            this.searchResultEmpty = true;
        else
            this.searchResultEmpty = false;
    }

    addFix() {
        this.searchResult = true;
        body.classList.add("offcanvas");
    }

    removeFix() {
        this.searchResult = false;
        body.classList.remove("offcanvas");
        this.text = "";
    }
    ngOnInit() {
        this.elem = document.documentElement;
        this.navServices.items.subscribe(menuItems => {
            this.items = menuItems
        });
    }

    SignOut() {
        this.authService.unLogin()
            .then(() => {
                this.router.navigate(['/auth/login']);
            })
            .catch(error => console.log(error));
    }

    toggleFullScreen() {
        this.navServices.fullScreen = !this.navServices.fullScreen;
        if (this.navServices.fullScreen) {
            if (this.elem.requestFullscreen) {
                this.elem.requestFullscreen();
            } else if (this.elem.mozRequestFullScreen) {
                /* Firefox */
                this.elem.mozRequestFullScreen();
            } else if (this.elem.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.elem.webkitRequestFullscreen();
            } else if (this.elem.msRequestFullscreen) {
                /* IE/Edge */
                this.elem.msRequestFullscreen();
            }
        } else {
            if (!this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }
}
