import { Injectable, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
    Auth,
    authState,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signOut,
    fetchSignInMethodsForEmail,
    deleteUser,
    getAuth,
    sendPasswordResetEmail,
    setPersistence,
    browserSessionPersistence,
    confirmPasswordReset
} from '@angular/fire/auth';
import {
    Firestore,
    collection,
    setDoc,
    getDoc,
    getDocs,
    CollectionReference,
    collectionData,
    doc,
    deleteDoc,
    updateDoc,
    query,
    or,
    where,
    and
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User } from '../model/user-management/user';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private items: CollectionReference;
    public userLogged: any;
    userData: any;

    constructor(
        public auth: Auth,
        public firestore: Firestore,
    ) {
        this.items = collection(this.firestore, 'user');
    }

    addUser(user: User) {
        user.password = "";
        return setDoc(doc(this.firestore, 'user', user.uid), user);
    }

    existEmail(email: string) {
        return fetchSignInMethodsForEmail(this.auth, email);
    }

    login({ email, password }: any) {
        return signInWithEmailAndPassword(this.auth, email, password);
    }

    createUser(user: User) {
        return createUserWithEmailAndPassword(this.auth, user.email, user.password);
    }

    deleteUserAccount() {
        deleteUser(this.userLogged);
    }

    getUsers() {
        this.items = collection(this.firestore, 'user');
        return collectionData(this.items, { idField: 'uid' }) as Observable<User[]>;
    }

    deleteUser(user: User) {
        const useref = doc(this.firestore, `user/${user.uid}`);
        return deleteDoc(useref);
    }

    updateUser(user: User) {
        const useref = doc(this.firestore, `user/${user.uid}`);
        return updateDoc(useref, {
            userName: user.userName,
            role: user.role,
            status: user.status
        });
    }

    updateUserTrainer(user: User) {
        const useref = doc(this.firestore, `user/${user.uid}`);
        return updateDoc(useref, {
            userName: user.userName,
            role: user.role,
            license: user.license,
            status: user.status
        });
    }

    updateStudentUser(user: User) {
        console.log('user', user)
        const useref = doc(this.firestore, `user/${user.uid}`);
        return updateDoc(useref, {
            address: user.address,
            birthDate: user.birthDate,
            fullName: user.fullName,
            gender: user.gender,
            phone: user.phone,
            status: user.status,
            trainerUid: user.trainerUid
        });
    }

    async unLogin() {
        return signOut(this.auth);
    }

    stateUser() {
        return authState(this.auth);
    }

    getTrainerList() {
        this.items = collection(this.firestore, 'user');
        let queryResult = query(this.items, or(where('role', '==', 'Trainer'))
        );
        return collectionData(queryResult, { idField: 'uid' }) as Observable<User[]>;
    }

    getTrainer(uid: string) {
        this.items = collection(this.firestore, 'user');
        let queryResult = query(this.items, and(where('uid', '==', uid), where('role', '==', 'Trainer'))
        );
        return collectionData(queryResult, { idField: 'uid' }) as Observable<User[]>;
    }



    async getUserData(uid: string) {
        const path = 'user';
        const id = uid;
        const params = doc(this.firestore, path, id);
        const data = await getDoc(params);
        return data;
    }


    getAllUsersList(uid: string) {
        this.items = collection(this.firestore, 'user');
        let queryResult = query(this.items, where('uid', '!=', uid)
        );
        return collectionData(this.items, { idField: 'uid' }) as Observable<User[]>;
    }


    async getAdminAndTrainerAndStudentUsersList() {
        this.items = collection(this.firestore, 'user');
        let queryResult = query(this.items, or(or(and(where('role', '==', TypeRole[1]), where('status', '==', 1)), and(where('role', '==', TypeRole[1]), where('status', '==', 2))),
            or(and(where('role', '==', TypeRole[2]), where('status', '==', 1)), and(where('role', '==', TypeRole[2]), where('status', '==', 2))),
            or(and(where('role', '==', TypeRole[3]), where('status', '==', 1)), and(where('role', '==', TypeRole[3]), where('status', '==', 2))))
        );
        // return collectionData(queryResult, { idField: 'uid' });
        const querySnapshot = await getDocs(queryResult);
        const data: any[] = [];
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });
        return data;
    }

    async getTrainerAndStudentUsersList() {
        this.items = collection(this.firestore, 'user');
        let queryResult = query(this.items, or(or(and(where('role', '==', TypeRole[2]), where('status', '==', 1)), and(where('role', '==', TypeRole[2]), where('status', '==', 2))),
            or(and(where('role', '==', TypeRole[3]), where('status', '==', 1)), and(where('role', '==', TypeRole[3]), where('status', '==', 2))))
        );
        // return collectionData(queryResult, { idField: 'uid' });
        const querySnapshot = await getDocs(queryResult);
        const data: any[] = [];
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });
        return data;
    }

    async getStudentUsersList(uid: string) {
        this.items = collection(this.firestore, 'user');
        if (uid == '') {
            let queryResult = query(this.items, or(and(where('role', '==', 'Student'),
                where('status', '==', 1)), and(where('role', '==', 'Student'),
                    where('status', '==', 2))));
            // return collectionData(queryResult, { idField: 'uid' }) as Observable<User[]>;
            const querySnapshot = await getDocs(queryResult);
            const data: any[] = [];
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
            return data;
        }
        else {
            let queryResult = query(this.items, or(and(where('role', '==', 'Student'),
                where('trainerUid', '==', uid),
                where('status', '==', 1)), and(where('role', '==', 'Student'),
                    where('trainerUid', '==', uid),
                    where('status', '==', 2))));
            // return collectionData(queryResult, { idField: 'uid' }) as Observable<User[]>;
            const querySnapshot = await getDocs(queryResult);
            const data: any[] = [];
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });
            return data;
        }
    }

    async ReferenceExist(uid: string) {
        let userArray: any[] = [];
        this.items = collection(this.firestore, 'user');
        let queryResult = query(this.items, or(and(where('role', '==', 'Student'),
            where('trainerUid', '==', uid),
            where('status', '==', 1)), and(where('role', '==', 'Student'),
                where('trainerUid', '==', uid),
                where('status', '==', 2))));

        const querySnapshot = await getDocs(queryResult);
        querySnapshot.forEach((doc) => {
            userArray.push(doc.data());
        });
        return userArray.length;
    }

    forgotPassword(email: string, uid: string) {
        const actionCodeSettings = {
            url: `https://fitness360-ad089.web.app/auth/reset-pwd`,
            handleCodeInApp: true
        };
        actionCodeSettings.url += `?uid=${uid}`;
        return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
    }

    updatePassword(oobCode: string, newPassword: string) {
        return confirmPasswordReset(this.auth, oobCode, newPassword);
    }

    async getUserByEmail(email: string) {
        const q = query(collection(this.firestore, 'user'), where('email', '==', email));
        const querySnapshot = await getDocs(q);
        const users: any[] = [];
        querySnapshot.forEach((doc) => {
            users.push(doc.data());
        });
        return users;
    }

    updateProfile(user: User) {
        const useref = doc(this.firestore, `user/${user.uid}`);
        return updateDoc(useref, { userName: user.userName });
    }
    updateLanguaje(user: User) {
        const useref = doc(this.firestore, `user/${user.uid}`);
        return updateDoc(useref, { language: user.language });
    }
}
export enum TypeRole { 'Sa', 'Admin', 'Trainer', 'Student' }
