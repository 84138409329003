import { Injectable, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
    Firestore,
    doc,
    updateDoc
} from '@angular/fire/firestore';
import { User } from '../../model/user-management/user';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(public firestore: Firestore) { }

  updateProfile(user: User) {
    const useref = doc(this.firestore, `user/${user.uid}`);
    return updateDoc(useref, {
      userName: user.userName,
      measurementSystem: user.measurementSystem,
    });
  }
  updateProfilePath(user: User) {
    const useref = doc(this.firestore, `user/${user.uid}`);
    return updateDoc(useref, {
      profilePath:user.profilePath
    });
  }
}
