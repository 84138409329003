import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ResetPwdComponent } from "./auth/reset-pwd/reset-pwd.component";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { content } from "./shared/routes/content-routes";
import { full } from "./shared/routes/full.routes";
import { AdminGuard } from "./shared/guard/admin.guard";

const routes: Routes = [
  {
    path: "", redirectTo: "anthropometrics-measures/measures", pathMatch: "full",
  },
  {
    path: "auth/login", component: LoginComponent,
  },
  {
    path: "auth/reset-pwd", component: ResetPwdComponent,
  },
  {
    path: "", component: ContentLayoutComponent, canActivate: [AdminGuard], children: content,
  },
  {
    path: "", component: FullLayoutComponent, canActivate: [AdminGuard], children: full,
  },
  {
    path: "**", redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
