import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../model/user-management/user';
import { AuthService } from '../../services/auth.service';
// import translationsEs from '../../../../assets/i18n/es.json';
// import translationsEn from '../../../../assets/i18n/en.json';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  // private translationsEs = translationsEs;
  private languageSubject = new BehaviorSubject<string>('en');
  language$ = this.languageSubject.asObservable();
  constructor(private translateService: TranslateService,
    private authService: AuthService) { }

  changeLanguage(language: string, user: User): void {
    this.translateService.use(language);
    this.languageSubject.next(language);
    this.authService.updateLanguaje(user);
    localStorage.setItem('userLogged', JSON.stringify(user))
  }
  translate(key: string): string {
    // console.log(this.languageSubject.value)
    return ''
    // if (!this.translationsEs[this.languageSubject]) {
    //   return key;
    // }
    // if (this.languageSubject == 'es'){
    //   return this.translationsEs[key] || key;
    // }
    // else{
    //   return this.translationsEs[key] || key;
    // }
  }
}
