import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

export interface LoginField {
	Email: string;
	Password: string;
	Login: string;
	ForgotPassword: string;
	LabeLogin: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoginFieldService {

	constructor() { }

	LOGINFIELDS: LoginField = {
		Email: 'Email',
		Password: 'Password',
		Login: 'Login',
		ForgotPassword: 'Forgot Password',
		LabeLogin: 'Label Login'
	}
	items = new BehaviorSubject<LoginField>(this.LOGINFIELDS);
}
