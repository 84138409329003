<div class="page-wrapper">
  <div class="auth-bg">
    <div class="authentication-box">
      <div class="text-center"><img src="assets/images/logo360.png" alt="" /></div>
      <div class="card mt-4">
        <div class="card-body">
          <div class="text-center">
              <h4>{{loginField.Login | translate}}</h4>
              <h6>{{loginField.LabeLogin | translate}}</h6>
          </div>
          <form class="theme-form" [formGroup]="loginForm">
            <div class="form-group">
                <label class="col-form-label pt-0">{{loginField.Email | translate}}</label>
              <input class="form-control" formControlName="email" [(ngModel)]="email" type="email" required="" />
              <!-- <div *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.['required']" class="text text-danger mt-1">Email is required</div>
              <div *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.['email']" class="text text-danger mt-1">Invalid Email</div> -->
            </div>
            <div class="form-group">
                <label class="col-form-label">{{loginField.Password | translate}}</label>
              <input class="form-control" type="password" formControlName="password" required="" [(ngModel)]="password" />
              <!-- <div *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']" class="text text-danger mt-1">Password is required</div>
              <div *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['minLength']" class="text text-danger mt-1">Min 6 characters</div> -->
            </div>
            <div class="form-group row mt-3 mb-0">
                <button class="btn btn-primary btn-block btn-lg" [disabled]="isLoading" (click)="login()" type="submit">
                    {{loginField.Login | translate}}
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
            <div style="text-align: center; margin-top: 10px; font-style: italic; font-size: large;">
                <a href="javascript:void(0);" (click)="forgotPassword(email)">{{loginField.ForgotPassword | translate}}?</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
