import { Component, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile/profile.service';
import { User } from '../../model/user-management/user';
import { ProfileUpdatedService } from '../../../shared/services/states/profile-updated.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy {

    public menuItems: Menu[] | any;
    public url: any;
    public fileurl: any;
    login: boolean = false;
    public userName: string;
    public rol: string = '';
    isAuthenticated = false;
    userLogged: any;
    public userModel: User;
    public currentYear: number;

    private profileUpdatedSubscription: Subscription;
    profileUpdateMessage: string = '';

    urlImgProfile: String = '';
    storage: any;

    constructor
        (
            private router: Router,
            public navServices: NavService,
            public authService: AuthService,
            private profileUpdatedService: ProfileUpdatedService,
            private profileService: ProfileService
        ) {
        this.currentYear = new Date().getFullYear();
        const app = initializeApp(environment.firebase);
        this.storage = getStorage(app);
        this.profileUpdatedSubscription = this.profileUpdatedService.profileUpdated$.subscribe((message) => {
            this.userName = message;
        });

        this.authService.stateUser().subscribe(async res => {
            if (res) {
                this.login = true;
                const data = await this.authService.getUserData(res.uid);
                this.userName = data?.data()?.['userName'];
                this.rol = data?.data()?.['role'];
                this.userModel = {
                    uid: res.uid,
                    email: data?.data()?.['email'],
                    userName: data?.data()?.['userName'],
                    password: data?.data()?.['password'],
                    role: data?.data()?.['role'],
                    trainerUid: data?.data()?.['trainerUid'],
                    profilePath: data?.data()?.['profilePath'],
                    measurementSystem: data?.data()?.['measurementSystem'] === '' || data?.data()?.['measurementSystem'] === undefined || data?.data()?.['measurementSystem'] === null ? 'metric' : data?.data()?.['measurementSystem'],
                    language: data?.data()?.['language'] ? data?.data()?.['language'] : 'en',
                }
                localStorage.setItem("userData", JSON.stringify(this.userModel));
                await this.getImages();
            }
            else {
                console.log('NO LOGGGGG');
                this.login = false;
            }
        });

        this.navServices.items.subscribe(menuItems => {
            this.menuItems = menuItems
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    menuItems.filter(items => {
                        if (items.path === event.url)
                            this.setNavActive(items)
                        if (!items.children) return false
                        items.children.filter(subItems => {
                            if (subItems.path === event.url)
                                this.setNavActive(subItems)
                            if (!subItems.children) return false
                            subItems.children.filter(subSubItems => {
                                if (subSubItems.path === event.url)
                                    this.setNavActive(subSubItems)
                            })
                            return
                        })
                        return
                    }
                    )
                }
            })
        })
    }

    async getImages() {
        const urlDefault = 'assets/images/user/1.jpg';
        try {
            const starsRef = ref(this.storage, this.userModel.profilePath);
            if (starsRef['_location'].path == '') {
                this.urlImgProfile = urlDefault;
            }
            else {
                this.urlImgProfile = await getDownloadURL(starsRef);
            }
        }
        catch (error) {
            this.urlImgProfile = urlDefault;
        }
    }

    async uploadImage($event: any) {
        try {
            const file = $event.target.files[0];
            if (file !== undefined) {
                const imgRef = ref(this.storage, `images/profile/${this.userModel.uid}`);
                const response = await uploadBytes(imgRef, file);
                this.userModel.profilePath = response.metadata.fullPath;
                this.profileService.updateProfilePath(this.userModel);
                await this.getImages();
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // Active Nave state
    setNavActive(item: any) {
        this.menuItems.filter((menuItem: any) => {
            if (menuItem != item)
                menuItem.active = false
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
            if (menuItem.children) {
                menuItem.children.filter((submenuItems: any) => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true
                        submenuItems.active = true
                    }
                })
            }
        })
    }

    // Click Toggle menu
    toggletNavActive(item: any) {
        if (!item.active) {
            this.menuItems.forEach((a: any) => {
                if (this.menuItems.includes(item))
                    a.active = false
                if (!a.children) return false
                a.children.forEach((b: any) => {
                    if (a.children.includes(item)) {
                        b.active = false
                    }
                })
                return
            });
        }
        item.active = !item.active
    }

    //Fileupload
    readUrl(event: any) {
        if (event.target.files.length === 0)
            return;
        //Image upload validation
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        // Image upload
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            this.url = reader.result;
        }
    }

    ngOnDestroy() {
        // No olvides desuscribirte para evitar fugas de memoria
        this.profileUpdatedSubscription.unsubscribe();
    }

}
