<div class="main-header-left d-none d-lg-block">
    <div class="logo-wrapper"><a href="javascript:void(0)"><img src="assets/images/logo360.png" height="42" alt=""></a></div>
</div>
<div class="sidebar-user text-center">
    <div>
        <img class="img-60 rounded-circle" [src]="urlImgProfile" alt="#">
        <div class="profile-edit">
            <a>
                <i class="icofont icofont-pencil-alt-5"><input class="upload" type="file" (change)="uploadImage($event)" /></i>
            </a>
        </div>
    </div>
    <h6 class="mt-3 f-14">{{userName}}</h6>
    <p>{{rol}}</p>
</div>
<div id="main" class="sidebar">
    <ul class="sidebar-menu p-3">
        <ng-container *ngFor="let menuItem of menuItems">
            <li [ngClass]="{active: menuItem.active}"
                *ngIf="menuItem.roles.includes(rol)">
                <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" [id]="menuItem.id" class="sidebar-header" *ngIf="menuItem.type === 'sub'"
                   (click)="toggletNavActive(menuItem)">
                    <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
                    <span>{{menuItem.title | translate}}<span class="badge badge-{{menuItem.badgeType}} me-3" *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
                    <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children.length > 0"></i>
                </a>
                <!-- 2nd Level Menu -->
                <ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
                    *ngIf="menuItem.children">
                    <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
                        <!-- Link -->
                        <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'"
                           routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fa fa-circle"></i><span>
                                {{childrenItem.title | translate}} <span class="badge badge-{{childrenItem.badgeType}} pull-right"
                                                                         *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span>
                            </span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                        </a>
                        <!-- 3rd Level Menu -->
                        <ul class="sidebar-submenu" *ngIf="childrenItem.children">
                            <li *ngFor="let childrenSubItem of childrenItem.children">
                                <!-- Link -->
                                <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                                   *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true}">
                                    <i class="fa fa-circle"></i><span>
                                        {{childrenSubItem.title | translate}} <span class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                                                                                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span>
                                    </span>
                                </a>
                                <!-- External Link -->
                                <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                                   *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true}">
                                    <i class="fa fa-circle"></i><span>
                                        {{childrenSubItem.title | translate}} <span class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                                                                                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span>
                                    </span>
                                </a>
                                <!-- External Tab Link -->
                                <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank"
                                   *ngIf="childrenSubItem.type === 'extTabLink'">
                                    <i class="fa fa-circle"></i><span>
                                        {{childrenSubItem.title | translate}} <span class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                                                                                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ng-container>
    </ul>
    <div class="position-absolute bottom-0 left-0 w-100 p-1 text-start">
        <hr class="mt-1 mb-1 text-bg-secondary" />
        <div class="m-1">
            <p class="mb-0 fw-bold" style="font-size: 12px; color: #808080;">Copyrigth {{currentYear}} Fitness360 {{ 'All rigths reserved' | translate }}.</p>
        </div>
        <div class="m-1">
            <p class="mb-0 fw-bold" style="font-size: 12px; color: #808080;">{{ 'Powered by' | translate }} Qallta Software.</p>
        </div>
    </div>
</div>