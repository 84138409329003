import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
	id?: number;
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	roles?: string[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?: any) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			id: 1, path: '/user-management/users', title: 'User Management', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/license-management/licenses', title: 'License Management', type: 'link' },
			], roles: ['Sa', 'Admin']
		},
		{
			id: 2, path: '/student-management/students', title: 'Student Management', icon: 'users', type: 'sub', active: false, children: [], roles: ['Sa', 'Admin', 'Trainer']
		},
		{
			id: 3, path: '/anthropometrics-measures/measures', title: 'Anthropometric measures', icon: 'crop', type: 'sub', active: false, children: [], roles: ['Sa', 'Admin', 'Student']
		},
		{
			id: 4, path: '/strength-assessments/assessments', title: 'Strength Assessments', icon: 'battery-charging', type: 'sub', active: false, children: [
				{ path: '/categories-management/categories', title: 'Categories', type: 'link' },
				{ path: '/muscle-group/muscles',title: 'Muscle Group', type: 'link' },
				{ path: '/exercises-management/exercises', title: 'Exercises', type: 'link' }
			], roles: ['Sa', 'Admin']
		},
		{
			id: 5, path: '/workout-routines/routines', title: 'Workout routines', icon: 'activity', type: 'sub', active: false, children: [], roles: ['Sa', 'Admin', 'Student']
		},
		{
			id: 6, path: '/reports/reports', title: 'Reports', icon: 'clipboard', type: 'sub', active: false, children: [], roles: ['Sa', 'Admin', 'Trainer', 'Student']
		}
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
