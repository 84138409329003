export const environment = {
  firebase: {
    projectId: 'fitness360-ad089',
    appId: '1:401623229821:web:41b88ad3ba4274aefbd814',
    storageBucket: 'fitness360-ad089.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBJNqyGqxpUrwb769P-tyvMMea2lbe7KX8',
    authDomain: 'fitness360-ad089.firebaseapp.com',
    messagingSenderId: '401623229821',
    measurementId: 'G-L9YX16058P',
  },
  production: true
};
