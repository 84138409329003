import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import { AuthService } from "../../shared/services/auth.service";
import { ToastrService } from 'ngx-toastr';
import { User } from '../../shared/model/user-management/user';
import { TranslateService } from '@ngx-translate/core';
import { LoginFieldService, LoginField } from "../../shared/services/language/login-field.service";

type UserFields = "email" | "password";
type FormErrors = { [u in UserFields]: string };

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    public userModel: User;

    public newUser = false;
    // public user: firebase.User;
    public loginForm: FormGroup;

    public errorMessage: any;
    public loginField: LoginField;
    public elem: any;

    uid: string;
    email: string;
    rol: string;
    password: string;
    status: number;
    public isLoading = false;

    constructor(private fb: FormBuilder,
        public router: Router,
        private authService: AuthService,
        public toast: ToastrService,
        private loginFieldService: LoginFieldService,
        private translate: TranslateService) {
        this.loginForm = this.fb.group({
            email: new FormControl(''),
            password: new FormControl(''),
        });
        this.changeLanguage('en');
    }

    ngOnInit() {
        localStorage.removeItem('userLogged');
        localStorage.removeItem('userData');
        localStorage.removeItem('user');
        this.elem = document.documentElement;
        this.loginFieldService.items.subscribe(resp => {
            this.loginField = resp;
        });
    }

    async login() {
        this.isLoading = true;
        if (this.loginForm.value.email !== '' && this.loginForm.value.password !== '') {
            await this.authService.login(this.loginForm.value).then(async resultLogin => {
                this.authService.userLogged = resultLogin.user;
                localStorage.setItem("user", JSON.stringify(this.loginForm.value));
                await this.authService.getUserData(resultLogin.user.uid).then(resultUser => {
                    this.rol = resultUser?.data()?.['role'];
                    this.status = resultUser?.data()?.['status'];
                    this.userModel = {
                        uid: resultUser?.data()?.['uid'],
                        email: resultUser?.data()?.['email'],
                        userName: resultUser?.data()?.['userName'],
                        password: '',
                        role: resultUser?.data()?.['role'],
                        trainerUid: resultUser?.data()?.['trainerUid'],
                        status: resultUser?.data()?.['status'],
                        birthDate: resultUser?.data()?.['birthDate'],
                        gender: resultUser?.data()?.['gender'],
                        language: resultUser?.data()?.['language'] ? resultUser?.data()?.['language'] : 'en',
                    }
                    localStorage.setItem("userLogged", JSON.stringify(this.userModel));
                    switch (this.status) {
                        case 1:
                            if (this.rol == 'Trainer') {
                                this.validateLicenseTrainer(this.userModel.uid).then(resultLicense => {
                                    console.log('resultLicense', resultLicense)
                                    if (resultLicense) {
                                        this.router.navigate(["/student-management/students"]);
                                        this.toast.success('Welcome to Fitness360!');
                                    }
                                    else {
                                        this.router.navigate(["/auth/login"]);
                                        this.toast.error("Expired license trainer or don't have license!");
                                        this.authService.unLogin();
                                    }
                                });
                            }
                            if (this.rol == 'Student') {
                                console.log('es estudiante...............')
                                this.validateLicenseTrainer(this.userModel.trainerUid).then(resultLicense => {
                                    console.log('resultLicense', resultLicense)
                                    if (resultLicense) {
                                        this.router.navigate(["/anthropometrics-measures/measures"]);
                                        this.toast.success('Welcome to Fitness360!');
                                    }
                                    else {
                                        this.router.navigate(["/auth/login"]);
                                        this.toast.error("Expired license trainer or don't have license!");
                                        this.authService.unLogin();
                                    }
                                })
                            }
                            if (this.rol == 'Admin' || this.rol == 'Sa') {
                                this.router.navigate(["/user-management/users"]);
                                this.toast.success('Welcome to Fitness360!');
                            }
                            break;
                        case 2:
                            this.router.navigate(["/auth/login"]);
                            this.toast.error('The account is Disabled');
                            this.authService.unLogin();
                            break
                        case 3:
                            this.router.navigate(["/auth/login"]);
                            this.toast.error('The account is deleted');
                            this.authService.unLogin();
                            break;
                    }
                });
            }).catch(error => {
                console.log(error);
                this.toast.error('Email or Password incorrect');
            });
        }
        else {
            this.toast.error('Email and Password fields are required');
        }
        this.isLoading = false;
    }

    async forgotPassword(email: string) {
        if (!email) {
            this.toast.error('Enter your email');
        }
        else {
            const data = await this.authService.getUserByEmail(email);
            if (data[0]) {
                this.uid = data[0].uid;
                await this.authService.forgotPassword(email, this.uid);
                this.toast.success('Check your email');
            }
            else {
                this.toast.error('Email does not exist');
            }
        }
    }

    async validateLicenseTrainer(userIdTrainer) {
        const result = await this.authService.getUserData(userIdTrainer);
        const daysLicense = result?.data()?.['license']?.['validity']?.['values'];
        if (daysLicense === undefined) {
            return false;
        }
        console.log('daysLicense', daysLicense);
        const seconds = result?.data()?.['license']?.['startDate'].seconds;
        const nanoseconds = result?.data()?.['license']?.['startDate'].nanoseconds;
        const startDateLicense: any = new Date(seconds * 1000 + nanoseconds / 1000000)
        console.log('startDateLicense', startDateLicense);
        const dateNow: any = new Date();
        console.log('dateNow', dateNow)
        const differences = dateNow - startDateLicense;
        const runDays = differences / (1000 * 60 * 60 * 24);

        console.log('Días corridos: ' + runDays);

        if (runDays > daysLicense) {
            return false;
        }
        return true;
    }

    public changeLanguage(lang: any) {
        this.translate.use(lang)
    }

    loginFacebook() {

    }
    loginTwitter() {

    }
    loginGoogle() {

    }
}
