import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileUpdatedService {

  private profileUpdatedSubject = new BehaviorSubject<string>('');

  // Método para notificar a otros componentes que se ha actualizado el perfil
  notifyProfileUpdated(message: string): void {
    this.profileUpdatedSubject.next(message);
  }

  // Observable para que los componentes puedan suscribirse a los cambios
  profileUpdated$: Observable<string> = this.profileUpdatedSubject.asObservable();

}
