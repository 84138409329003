<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">Copyrigth 2023 Fitness360 All rigths reserved.</p>
    </div>

    <div class="col-md-6">
      <p class="pull-right mb-0">Powered by Qallta Software</p>
    </div>
  </div>
</div>
