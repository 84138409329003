import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss']
})
export class ResetPwdComponent {

  newPassword: string;
  confirmPassword: string;
  uid: string;
  oobCode: string;
  email: string;

  constructor ( public toast: ToastrService, 
                private route: ActivatedRoute,
                private authService: AuthService,
                private router: Router ) 
  {
    this.route.queryParams.subscribe(params => {
      const continueUrl = params['continueUrl'];
      console.log({continueUrl});
      this.uid = continueUrl.split("uid=")[1];
      console.log('uid:', this.uid);
      this.oobCode = params['oobCode'];
      console.log('oobCode:', this.oobCode);
    });
    console.log('aqui',this.uid);
    this.authService.getUserData(this.uid).then(data => this.email = data?.data()?.['email']);;
    console.log(this.email);
  }

  onSubmit() {
    if (this.newPassword === this.confirmPassword) {
      this.authService.updatePassword(this.oobCode, this.newPassword);
      this.toast.success('Password updated successfully');
      this.authService.unLogin();
      this.router.navigate(['/auth/login']);
    } else {
      this.toast.error('Passwords do not match');
    }
  }

  onCancel() {
    this.toast.warning('Password not changed');
    this.authService.unLogin();
    this.router.navigate(['/auth/login']);
  }

}
